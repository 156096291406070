<template>
  <div class="invoices">

    <div class="wrapper wrapper-content animated fadeInDown">
      <div class="row">
        <div class="col-lg-12">
          <div class="ibox">
            <div class="ibox-content no-borders b-r-lg">

              <!-- table -->
              <div class="row">
                <div class="col-lg-12">
                  <div class="panel blank-panel">

                    <b-tabs content-class="mt-3" fill>
                      <b-tab v-for="tab in tabs" :title="$t(tab.label)">
                        <default-invoice-page :showRentalClientSelector="tab.showRentalClientSelector"
                                              :showDriverSelector="tab.showDriverSelector"/>
                      </b-tab>
                    </b-tabs>

                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import DefaultInvoicePage from "@/components/default_invoice_page";

export default {
  components: {DefaultInvoicePage},
  data() {
    return {
      tabs: [
        {
          label: 'multiples.credit_note.other',
          showRentalClientSelector: false,
          showDriverSelector: false
        },
        {
          label: 'multiples.principal.other',
          showRentalClientSelector: true,
          showDriverSelector: false
        },
        {
          label: 'multiples.driver.other',
          showRentalClientSelector: false,
          showDriverSelector: true
        }
      ]
    }
  }
}
</script>